import { api } from '@/config/axios/index';

export default {
  sendEmail( form ) {
    return api({
      method: 'POST',
      url: process.env.VUE_APP_EMAIL_URL,
      data: form,
    });
  }
};